.serviceBody {
display: flex;
    align-items: center;
    justify-content: center;
margin-bottom: 150px;
    margin-top: 150px;
}

.container {
    width: 70vw;
        height: auto;
        color: #36454F;
        box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.6);
        background-color: #181c28;
        border-radius: 20px;
        padding: 30px 20px;
        opacity: 0.9;
        margin: 50px;
        /* display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; */
}

.title {
    border-radius: 20px;
        /* height: 100%; */
        width: auto;
        font-size: 2.3rem;
        font-family: "Fasthand";
        font-weight: bolder;
        color: white;
}

.serviceContainer {
    display: grid;
    grid-template-columns: repeat(3, 20vw);
    grid-template-rows: repeat(1, 20vw);
    justify-content: space-evenly;
    column-gap: 10px;
    height: auto;
}

.inServiceContainer {
    /* width: 30%; */
    height: 100%;
    background-color: aliceblue;
    position: relative;
    transition: width 0.9s ease-in-out, height 0.9s ease-in-out;
    overflow: hidden;
    border-radius: 15px;
    opacity: 0.6;
    box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.7);
}

/* .inServiceContainer:hover {
    width: 32%;
    height: 102%;
    transition: 0.8;
} */

.inServiceContainer img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.inServiceContainer h2 {
    color: white;
    /* text-shadow: 1px 1.5px darkred ; */
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
}

.skills {
    /* border-radius: 20px; */
    height: 100%;
    width: 100%;
    font-size: 1.3rem;
    font-family: "Fasthand";
    font-weight: bolder;
    color: white;
    margin-top: 20px;
}

.skillsContainer {
    display: grid;
    grid-template-columns: repeat(3, 21vw);
    justify-content: space-evenly;
    column-gap: 5px;
}

.skillsWrapper {
    /* width: 220px; */
    height: 5vh;
    background-color: darkred;
    border-radius: 7px;
    /* opacity: 0.9; */
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.7);
}

.skillsWrapper p {
   font-weight: 600;
   color: aliceblue;
}

@media (max-width: 1100px) {
    .container {
        width: 75vw;
        margin: 25px;
    }
        .serviceContainer {
            grid-template-columns: repeat(3, 22.5vw);
            grid-template-rows: repeat(1, 22.5vw);
        }
                .skillsContainer {
                    grid-template-columns: repeat(3, 22.5vw);
                }
    .serviceBody {
        height: 100vh;
        margin-bottom: 150px;
        margin-top: 150px;
    }
}

@media (max-width: 1000px) {
    .inServiceContainer h2 {
        font-size: 1rem;
    }
        .skillsWrapper p {
            font-size: 0.9rem;
        }
}

@media (max-width: 765px) {
    .inServiceContainer h2 {
        font-size: 0.9rem;
    }
        .skillsWrapper p {
            font-size: 0.75rem;
        }
    .skillsWrapper {
        height: 4vh;
    }
    .container {
        width: 70.5vw;
    }

    .serviceContainer {
        grid-template-columns: repeat(3, 22vw);
        grid-template-rows: repeat(1, 22vw);
    }
}

@media (max-width: 750px) {
    .container {
            width: 80vw;
        }
                        .serviceContainer {
                            grid-template-columns: repeat(3, 26vw);
                            grid-template-rows: repeat(1, 26vw);
                        }
                        .skillsContainer {
                            grid-template-columns: repeat(3, 25vw);
                        }
}

@media (max-width: 701px) {
    .inServiceContainer h2 {
        font-size: 0.85rem;
    }
        .skillsWrapper p {
            font-size: 0.75rem;
       }
}