.portBody {
    display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        margin-bottom: 150px;
            margin-top: 150px;
}

.container {
    width: 70vw;
    height: auto;
    color: #36454F;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.6);
    background-color: #181c28;
    border-radius: 20px;
    padding: 30px 20px;
    opacity: 0.9;
    margin: 30px;
}

.pageTitle {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    font-size: 2.3rem;
    font-family: "Fasthand";
    font-weight: bolder;
    color: white;
}

.descContainer {
    width: auto;
    height: auto;
    background-color: #181c28;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    padding: 15px;
    opacity: 0.6;
    color: white;
    border-radius: 15px;
    line-height: 1.75;
    box-shadow: 4px 4px 6px 6px rgba(0, 0, 0, 0.7);
}

.catsLabelContainer {
    display: flex;
    justify-content: center;
    margin-top: 7vh;
    margin-bottom: 3vh;
}

.catsLabelActive{
    background-color: darkred;
    color: aliceblue;
    padding: 5px 10px;
    font-size: 1.0em;
    width: 120px;
    border-radius: 50px;
    text-align: center;
    margin: 2px 10px;
    font-weight: 650;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.7);
}

.catsLabelInactive {
    background-color: aliceblue;
    padding: 5px 10px;
    width: 100px;
    font-style: italic;
    border-radius: 50px;
    text-align: center;
    margin: 2px 10px;
    font-weight: 500;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.7);
}

.catsLabelInactive:hover {
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    color: aliceblue;
    background-color: darkgoldenrod;
}

/* .catsLabel:active {
    background-color: darkred;
    font-weight: bolder;
    font-style: normal;
} */

.dataOuterContainer {
    display: inline-grid;
    grid-template-columns: repeat(3, 21.5vw);
    width: 100%;
    align-items: center;
    column-gap: 10px;
    row-gap: 23px;
    justify-content: space-evenly;
}

.dataItemContainer {
    margin-right: auto;
        margin-left: auto;
    overflow: hidden;
    width: 21.5vw;
    height: 21.5vw;
    background-color: aliceblue;
        position: relative;
        overflow: hidden;
        border-radius: 15px;
        /* opacity: 0.9; */
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.7);
        /* transition: width .5s ease, height ease .5s; */
}

.dataItemContainer:hover {
    cursor: pointer;
    /* width: 23vh;
    height: 23vh; */
}

.dataItemContainer img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.dataItemContainer p{
    position: absolute;
    bottom: -20px;
    /* Initial position below the container */
    opacity: 0;
    /* Initially invisible */
    transition: bottom 0.5s ease, opacity 0.5s ease, color 0.5s ease, font-weight 0.5s ease, font-style 0.5s ease;
    /* Transition properties */
    background-color: darkred;
    /* opacity: 0.5; */
    font-weight: 500;
    font-style: italic;
    color: darkgoldenrod;
    text-align: center;
    padding: 10px 1px;
    text-overflow: clip;
    width: 100%;
    font-size: 0.9rem;
}

.dataItemContainer:hover p{
    bottom: 0px;
    /* Position when hovering */
    font-weight: bold;
    opacity: 1;
    font-style: normal;
    /* Make it visible */
    color: white;
    text-align: center;
}

.popup {
    width: 100vw;
    /* height: 100vh; */
    background-color: #181c28;
}

.nonMobContainer {
    height: 89vh;
    width: 48vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181c28;
}

.nonMobContainer .nonMobileImage {
    height: 80vh;
    width: 95%;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.nonMobContainer a {
    width: 25vh;
    max-width: 30vh;
    padding: 5px 10px;
    border: none !important;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    /* margin-left: auto;
    margin-right: auto; */
    margin: 12px auto;
    border-radius: 7px;
    background-color: darkred;
    color: aliceblue;
    display: block;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.7);
}

.mobileContainer {
    height: 85vh;
   background-color: #181c28;
    width: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
 }

.mobLinkImgContainer {
    width: 10vw;
}

.displayImageContainer {
    width: 20vw;
}

.mobileImage {
    height: 80vh;
    width: 28vw;
    object-fit: contain;
}

.android {
    height: 17vh;
    width: 17vh;
    margin: 20px;
    box-shadow: 2px 2px 2px 2px rgba(245, 232, 232, 0.8);
    border-radius: 10px;
    transition: height 0.5s ease, width 0.5s ease;
    object-fit: contain;
}

.android:hover {
    cursor: pointer;
    width: 19vh;
    height: 19vh;
}

.ios {
    height: 17vh;
    width: 17vh;
    margin: 20px;
    object-fit: cover;
    box-shadow: 2px 2px 2px 2px rgba(245, 232, 232, 0.8);
    border-radius: 10px;
    transition: height 0.5s ease, width 0.5s ease;
}

.ios:hover {
    cursor: pointer;
    width: 19vh;
    height: 19vh;
}

.appTtle {
    display: block;
}

@media (max-width: 1100px) {
    .dataOuterContainer {
        grid-template-columns: repeat(3, 22vw);
        column-gap: 5px;
        row-gap: 15px;
    }
    .dataItemContainer p{
        font-size: 0.75rem;
    }

    .portBody {
        margin-bottom: 150px;
            margin-top: 150px;
    }
}

@media (max-width: 750px) {
    .container {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 80vw;
        height: auto;
    }
    .dataOuterContainer {
            grid-template-columns: repeat(auto-fill, 26vw);
            justify-content: center;
            column-gap: 5px;
                row-gap: 25px;
            border-radius: 10px;
        }
    .catsLabelContainer {
        margin-top: 1.8rem;
    }
        .dataItemContainer p {
            width: 100%;
            font-size: 0.7rem;
            text-overflow: clip;
            text-align: center;
            margin-right: 5px;
        }
                .dataItemContainer:hover p{
                    width: 100%;
                        font-size: 0.72rem;
                        text-overflow: clip;
                        text-align: center;
                        margin-right: 5px;
                }
        .catsLabelActive {
            font-size: 0.85rem;
        }
                .catsLabelInactive {
                    font-size: 0.8rem;
                }
                .descContainer {
                    font-size: 0.85rem;
                }
}


@media (max-width: 700px) {
    .dataItemContainer p {
        font-size: 0.65rem;
        padding: 7px 1px;

    }

    .dataItemContainer:hover p {
        font-size: 0.65rem;
    }

}



@media (max-width: 500px) {
    .dataOuterContainer {
            grid-template-columns: repeat(2, 40vw);
            /* justify-content: center; */
            column-gap: 5px;
            row-gap: 25px;
            border-radius: 10px;
        }

        .dataItemContainer {
            overflow: hidden;
            width: 39vw;
            height: 39vw;
        }
}