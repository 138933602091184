.body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background-color: whitesmoke;
    border-radius: 20px;
    display: flex;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    color: #894414;
    font-weight: bolder;
    height: 30vh;
    width: 60vw;
}





