.experienceBody {
    display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 150px;
            margin-top: 150px;
}

.container {
    width: 70vw;
    height: auto;
    color: #36454F;
        box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.6);
        background-color: #181c28;
        border-radius: 20px;
        padding: 20px 15px;
        opacity: 0.9;
        margin: 30px;
}

.pageTitle {
    height: 100%;
        width: 100%;
        font-size: 2.3rem;
        font-family: "Fasthand";
        font-weight: bolder;
        color: white;
}

.descContainer {
    width: 95%;
    height: auto;
    background-color: #181c28;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    padding: 20px;
    opacity: 0.6;
    border-radius: 20px;
    line-height: 1.75;
    box-shadow: 4px 4px 6px 6px rgba(0, 0, 0, 0.7);
}

.descTitle {
    font-weight: bolder;
    font-size: 1.2rem;
    color: darkorange;
}

.company {
    font-weight: 600;
    color: white;
}

.date {
    font-style: italic;
    color: white;
}

.descContainer ul li {
    list-style: none;
    color: white;
}

.moreContainer {
    margin-top: 15px;
    display: flex;
        justify-content: flex-end;

}

.seeMore {
    background-color: darkred;
    width: auto;
    height: auto;
    padding: 10px 15px;
    border-radius: 5px;
}

.seeMoreText:hover {
    color: aliceblue;
        font-style: normal;
        font-weight: bold;
        transition: 0.8;
}

.seeMoreText {
    color: aliceblue;
    font-style: italic;
    transition: 0.8;
}


@media (max-width: 1100px) {
    .experienceBody {
        margin-bottom: 150px;
            margin-top: 150px;
    }
}

@media (max-width: 1030px) {
    .descContainer {
        width: 92%;
    }
        .container {
            width: 80vw;
        }
}

@media (max-width: 850px) {
    .descTitle {
        font-size: 1.1rem;
    }
    .company {
        font-size: 0.95rem;
    }
    .date {
        font-size: 0.95rem;
    }
.descContainer ul li {
    font-size: 0.9rem;
}
.container{
            padding: 10px 15px;
            width: 80vw;
            margin: 10px;
}
.descContainer {
    width: 90%;
}
}

@media (max-width: 750px) {
    .container {
            width: 80vw;
        }
}