.copyrightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    margin-top: 20%;
    color: whitesmoke;
    font-weight: bolder;
    font-size: 0.7rem;
}

@media (max-width: 620) {
    .copyrightContainer {
        font-size: 0.65rem;
    }
}