body {
 margin: 0;
 padding: 0;
background: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}

