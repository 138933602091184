.navBody {
    height: 22vh;
    width: 100%;
    background: #202d3b;
    border-bottom: 1.9px solid #172029;
    display: flex;
    position: fixed;
    z-index: 5;
    align-items: center;
}

.port {
    font-size: 1.8rem;
    font-family: "Fasthand";
    font-weight: bolder;
    color: #f1c36b;
    text-shadow: #181c28;
    /* color: darkorange; */
}

.leftContainer {
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileIconList {
    height: auto;
    /* width: 120%; */
    padding: 0;
    margin-top: 5px;
    display: flex;
    align-items: center;
    /* background-color: #f1c36b; */
    justify-content: space-evenly;
}

.nameProfileContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* height: 18vh; */
    /* margin-left: 1.5vw; */
}

.row {
    list-style-type: none;
    color: whitesmoke;
    width: 3vw;
    height: 3vw;
    background-color: #181c28;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 70%;
    margin: 0px 2px;
}

.imageContainer {
    width: 13.5vw;
    height: 13.5vw;
    background-color: #181c28;
    border-radius: 100%;
    /* margin: 0; */
    display: flex;
    margin-top: 2px;
    align-items: center;
    justify-content: center;
}

.imageContainer .image {
    width: 12vw;
    height: 12vw;
    border-radius: 100%;
    object-fit: cover;
}

/* #username {
    color: whitesmoke;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    margin: 0;
    margin-top: 4px;
} */

.rightContainer {
    width: 80vw;
}

.dataIcon {
    color: whitesmoke;
}

.row:hover {
    cursor: pointer;
    background-color: #894414;
    /* width: 35px; */
    /* height: 35px; */

}

.navList {
    height: 10vh;
        width: 80vw;
        flex-wrap: wrap;
        padding: 0;
        color: aliceblue;
        font-style: italic;
        font-size: 0.85rem;
        transition: font-style 0.9s ease;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
}

#row {
    list-style-type: none;
    color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 1.8vw;
    margin-right: 1.8vw;
}

#row:hover {
    cursor: pointer;
    color: darkorange;
}

.active {
    width: 100%;
    color: #f1c36b;
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    list-style-type: none;
    margin: 4px 0;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    transition: 0.4s;
}

@media (min-width: 750px) {
    .navBody {
        display: none;
    }
}

@media (max-width: 500px) {
    .port {
        font-size: 1.4rem;
        margin-bottom: 0.2rem;
   }
   #username {
        font-size: 1.02rem;
        margin-bottom: 0.2rem;
   }
   .profileIconList {
       margin-bottom: 0.9rem;
   }

}


@media (max-width: 450px) {
    .navBody {
        height: 13vh;
    }

    .leftContainer {
        padding-left: 7px;
    }
}

@media (max-width: 400px) {
    .port {
        font-size: 1.4rem;
        margin-bottom: 0.2rem;
    }

    #username {
        font-size: 0.95rem;
        margin-bottom: 0.2rem;
    }
    .profileIconList {
        margin-bottom: 0.9rem;
    }

    .active {
        font-size: 0.9rem;
    }
    #title {
        font-size: 0.8rem;
    }
}

@media (max-width: 380px) {
    .port {
        font-size: 1.2rem;
    }

    #username {
        font-size: 0.9rem;
    }

    .active {
        font-size: 0.87rem;
    }

    #title {
        font-size: 0.78rem;
    }
}

@media (max-width: 330px) {
    .port {
        font-size: 1rem;
        margin-bottom: 0.05rem;
    }

    #username {
        font-size: 0.7rem;
    }

    .active {
        font-size: 0.85rem;
    }

    #title {
        font-size: 0.76rem;
    }
}