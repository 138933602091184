.navBody {
    position: sticky;
    top: 0;
    bottom: 0;
    width: 20%;
    height: 100vh;
    background: #2c3e50;
    margin: 0;
    border-right: 1.5px solid #172029;
}

.sidebarList {
    height: auto;
    width: 100%;
    padding: 0;
    color: aliceblue;
    font-style: italic;
    font-size: 0.9rem;
    transition: font-style 0.9s ease;
}

.sidebarList .row:hover{
    cursor: pointer;
    background-color: #181c28;
    opacity: .7;
    font-style: italic;
    font-weight: bolder;
}

.active {
    width: 100%;
    height: 38px;
    background-color: #181c28;
    color: #f1c36b;
    font-size: 1.2em;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 2px 0.5px;
    list-style-type: none;
    margin: 4px 0;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    transition: 0.4s;
}

#inactive {
    width: 100%;
    height: 38px;
    /* color: whitesmoke; */
    /* font-size: 0.7.em; */
    /* font-style: italic; */
    text-decoration: none;
    padding: 2px 0.5px;
    margin: 4px 0;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    transition: 0.4s;
}

.row #icon {
    flex: 30;
    display: grid;
    place-items: center;
}

.row #title {
    flex: 70;
}

@media (max-width: 750px) {
    .navBody {
        display: none;
    }
}

@media (max-width: 750px) {
    .navBody {
        display: none;
    }
}

@media (max-width: 750px) {
    .active {
        font-size: 0.9rem;
    }
    .sidebarList {
        font-size: 0.8rem;
    }
}