.homebody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
}

.container {
    width: 70vw;
    height: auto;
    color: #36454F;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.6);
    background-color: #181c28;
    border-radius: 20px;
    padding: 30px 20px;
    opacity: 0.9;
    margin: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .container:hover {
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.7);
    width: 76%;
    height: 66%;
    transition: 0.7;
} */

.content {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 60%;

}

.greetings {
    font-size: 4rem;
    color: #E1D9D1;
    text-shadow: 3px 3px #000;
}

.homename {
    font-size: 2rem;
    color: #E1D9D1;
    /* color: #918981; */
    text-shadow: 3px 3px #000;
    font-style: italic;
}

b {
    font-style: normal;
}


.profile {
    font-size: 1.8rem;
    color: #f1c36b;
    text-shadow: 3px 3px #000;
    font-style: italic;
}

Typical {
    font-style: normal;
    font-size: 3em
}

.homeDescContainer {
    box-shadow: 5px 5px 8px 8px rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
    background-color: #181c28;
    padding: 15px;
    border-radius: 10px;
    opacity: 0.7;
    width: 70%;
    margin: 30px 0px;
    color: white;
    /* color: #181c28; */
    line-height: 1.5em;
    margin-top: 20px;
}

@media (max-width: 1100px) {
    .profile {
        font-size: 1.5rem;
    }
    .content{
    width: 65%;
    }
    .homeDescContainer{
        width: 80%;
        font-size: 0.95rem;
    }
}

@media (max-width: 750px) {
    .container {
        margin-top: 180px;

    }
}

@media (max-width: 720px) {
    .profile {
        font-size: 1.3rem;
    }

    .content {
        width: 65%;
    }
    .homename {
        font-size: 1.7rem;
    }
    .homeDescContainer {
        width: 80%;
        font-size: 0.9rem;
    }
    /* .container {
     margin-bottom: 30%;
     margin-top: 30%;
    } */
}

@media (max-width: 800px) {
    .profile {
        font-size: 1.2rem;
    }

    .content {
        width: 70%;
    }

    .homename {
        font-size: 1.63rem;
    }

    .homeDescContainer {
        width: 80%;
        font-size: 0.9rem;
    }
}

@media (max-width: 670px) {
    .greetings{
        font-size: 2.4rem;
    }
    .homename {
        font-size: 1.55rem;
    }
        .content {
            width: 80%;
        }
}