.aboutbody {
    display: flex;
        align-items: center;
        justify-content: center;
        /* height: 100vh; */
        margin-bottom: 150px;
            margin-top: 150px;
}

.majorContainer {
    width: 70vw;
    height: auto;
    max-height: 100%;
    color: #36454F;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.6);
    background-color: #181c28;
    border-radius: 20px;
    padding: 35px 20px;
    opacity: 0.9;
    margin: 50px;
    /* margin-bottom: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.topContainer {
    width: 90%;
    height: auto;
    background-color: #181c28;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    opacity: 0.6;
    border-radius: 20px;
    box-shadow: 4px 4px 6px 6px rgba(0, 0, 0, 0.7);
}

.titleContainer{
    border-radius: 20px;
    height: 100%;
    width: 100%;
    display: inline;
    font-size: 2.3rem;
    font-family: "Fasthand";
    font-weight: bolder;
    /* color: red; */
    color: red;
}

.topTextContainer {
    border-radius: 20px;
    height: auto;
    width: 90%;
    padding: 10px;
    line-height: 1.7em;
    color: white;
}

.midContainer {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    border-radius: 20px;
}

.outerContainer {
    width: 44%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: #181c28;
    opacity: 0.6;
    border-radius: 20px;
    box-shadow: 4px 4px 6px 6px rgba(0, 0, 0, 0.7);
    padding: 5px;
}

.innerContainer {
    display: flex;
    align-items: center;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 2px 10px 2px;
}

.outerContainer ul {
    list-style: none;
}

.innerContainer .title {
    font-weight: 550;
}

.innerContainer .data{
    font-weight: 500;
}

.bottomContainer {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background-color: #181c28;
    color: white;
    margin-top: 20px;
    box-shadow: 4px 4px 7px 7px rgba(0, 0, 0, 0.7);
    padding: 10px;
    opacity: 0.6;
    border-radius: 20px;
    line-height: 1.7em;
}

@media (max-width: 1100px) {
    .majorContainer{
        width: 75vw;
        margin: 25px;
    }
    .topContainer {
        width: 95%;
    }
    .topTextContainer {
        width: 95%;
    }
    .bottomContainer {
        width: 95%;
    }

}

@media (max-width: 1030px) {
    .midContainer{
        width: 95%;
    }

}

@media (max-width: 980px) {
    .midContainer {
        width: 100%;
    }

}

@media (max-width: 935px) {
    .majorContainer {
        padding: 25px 10px;
    }
    .outerContainer {
        width: 46%;
    }
    .topContainer {
        width: 100%;
    }
}

@media (max-width: 910px) {
    .outerContainer {
        width: 46%;
    }
        .majorContainer {
            width: 80vw;
            margin: 15px;
            height: 80%;
            justify-content: space-evenly;
        }

        .topContainer {
            width: 100%;
        }

        .topTextContainer {
            width: 100%;
        }

        .bottomContainer {
            width: 97%;
        }
}

@media (max-width: 850px) {
    .outerContainer {
        width: 47%;
        height: 95%;
    }

    .majorContainer {
        width: 80vw;
        margin: 15px;
        height: 85%;
        justify-content: space-evenly;
    }

}


@media (max-width: 830px) {
    .title {
        font-size: 1rem;
    }
    .data {
        font-size:  0.9rem;
    }
}

@media (max-width: 830px) {
    .title {
        font-size: 0.95rem;
    }

    .data {
        font-size: 0.85rem;
    }
    .topContainer{
        font-size: 0.95rem;
    }
    .bottomContainer {
        font-size: 0.95rem;
    }
}

@media (max-width: 750px) {
    .innerContainer {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;

    }

    .majorContainer {
       max-height: 98%;
       width: 80vw;
        margin: 50px 10px;
        padding: 35px 20px;
        margin-top: 70px;
    }

    .title {
        font-weight: bolder;
        font-size: 1rem;
        color: darkorange;
    }
    .bottomContainer {
            width: 95%;
    }
}

@media (max-width: 695px) {
    .majorContainer {
        max-height: 100%;
    }

    .topTextContainer p {
        font-size: 0.8rem;
    }

    .title {
        font-size: 0.9rem;
    }

    .data {
        font-size: 0.8rem;
    }

    .titleContainer{
        font-size: 2rem;
    }

    .bottomContainer {
        font-size: 0.8rem;
    }

}

@media (max-width: 601px) {
    .outerContainer {
        width: 43%;
    }
}