.contactBody {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 80%; */
    margin-bottom: 150px;
        margin-top: 150px;
}

.container {
    width: 70vw;
    height: auto;
    color: #36454F;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.6);
    background-color: #181c28;
    border-radius: 20px;
    padding: 20px;
    opacity: 0.9;
    margin: 30px;
}

.pageTitle {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    font-size: 2.3rem;
    font-family: "Fasthand";
    font-weight: bolder;
    color: white;
}

.helloContainer {
    width: auto;
        height: auto;
        background-color: #181c28;
        font-style: italic;
        font-weight: 600;
        font-size: 1.1rem;
        margin-left: auto;
        margin-right: auto;
        color: white;
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 15px;
        opacity: 0.6;
        border-radius: 15px;
        line-height: 1.75;
        box-shadow: 4px 4px 6px 6px rgba(0, 0, 0, 0.7);
}

.hello {
    font-size: 2rem;
    font-weight: 900;
    color: darkorange;
}

.nameandemail {
    display: flex;
    /* align-items: center; */
    justify-content: space-evenly;
    /* height: 6vh; */
}

.contactName {
    border-radius: 5px;
    padding: 10px;
    border: 0px;
}

.contactEmail {
    border-radius: 5px;
    padding: 10px;
    border: 0px;
}

input {
    width: 30vw;
}

input,
textarea {
    font-family: inherit;
    font-size: inherit;
}

.subject {
    border-radius: 5px;
        padding: 10px;
        border: 0px;
}
.subjectContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 20px 0px;

    /* width: 63.5vw; */
}

.messageContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 20px 0px;
}


textarea {
    width: 63.5vw;
    resize: vertical;
    padding: 15px;
    border-radius: 15px;
    border: 0;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.06);
    height: 30vh;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;

}

button {
    padding: 12px 10px;
    border-radius: 7px;
    background-color: darkred;
    color: aliceblue;
    font-size: 0.8rem;
    font-weight: 600;
    width: 20vw;

}

button:hover {
    cursor: pointer;
}

@media (max-width: 1100px) {
.container {
    width: 80vw;
}
}

@media (max-width: 900px) {
    .helloContainer p .hello {
            font-size: 1.7rem;
        }
    .helloContainer p {
            font-size: 0.95rem;
    }
}

@media (max-width: 750px) {
    .helloContainer p .hello {
        font-size: 1.5rem;
    }

    .helloContainer p {
        font-size: 0.85rem;
    }
    button {
        font-size: 0.7rem;
    }
}

@media (max-width: 600px) {
    button {
       width: 25vw;
    }
}
