.indexBody {

    background: #293a4b;
    width: 80%;
}

@media (max-width: 750px) {
    .indexBody {
        width: 100%;
        margin: 0;
        background: #2c3e50;
    }
}
