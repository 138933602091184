.articleBody {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
        margin-top: 150px;
}

.container {
    width: 70vw;
    height: auto;
    color: #36454F;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.6);
    background-color: #181c28;
    border-radius: 20px;
    padding: 30px 20px;
    opacity: 0.9;
    margin: 30px;
}

.pageTitle {
    border-radius: 20px;
    /* height: 100%;
    width: 100%; */
    font-size: 2.3rem;
    font-family: "Fasthand";
    font-weight: bolder;
    color: white;
}

.pageDesc {
    width: auto;
    height: auto;
    background-color: #181c28;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 15px;
    color: white;
    opacity: 0.6;
    border-radius: 15px;
    line-height: 1.75;
    box-shadow: 4px 4px 6px 6px rgba(0, 0, 0, 0.7);
}

.gridContainer {
    display: inline-grid;
        grid-template-columns: repeat(3, 21.5vw);
        width: 100%;
        align-items: center;
        column-gap: 10px;
        row-gap: 23px;
        justify-content: space-evenly;

}

.gridItemContainer {
margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    width: 21.5vw;
    height: 21.5vw;
    background-color: aliceblue;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    /* opacity: 0.9; */
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.7);
    /* transition: width .5s ease, height ease .5s; */
}

.gridItemContainer:hover {
    cursor: pointer;
    /* width: 43vh;
    height: 43vh; */
}

.gridItemContainer img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.gridItemContainer p {
    position: absolute;
        bottom: -20px;
        /* Initial position below the container */
        opacity: 0;
        /* Initially invisible */
        transition: bottom 0.5s ease, opacity 0.5s ease, color 0.5s ease, font-weight 0.5s ease, font-style 0.5s ease;
        /* Transition properties */
        background-color: darkred;
        /* opacity: 0.5; */
        font-weight: 500;
        font-style: italic;
        color: darkgoldenrod;
        text-align: center;
        padding: 10px 1px;
        text-overflow: clip;
        width: 100%;
        font-size: 0.9rem;
}

.gridItemContainer:hover p {
    bottom: 0px;
    /* Position when hovering */
    font-weight: bold;
    opacity: 1;
    font-style: normal;
    /* Make it visible */
    color: white;
    text-align: center;
}

.gridItemContainer .linksContainer {
    position: absolute;
    top: 35%;
    opacity: 0;
    transition: opacity 0.9s ease, top 0.9s ease,;
    background-color: darkred;
    height: 10px;
    width: 45%;
    text-align: center;
    left: 22%;
    right: 22%;
    font-weight: 500;
    /* font-style: italic; */
    text-align: center;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px rgba(41, 5, 5, 0.9);
    padding: 10px 15px;
    font-size: 0.76rem;
}

.gridItemContainer:hover .linksContainer {
    align-self: center;
    height: 10px;
    width: 45%;
    top: 40%;
    font-size: 0.76rem;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    color: aliceblue;
    opacity: 1;
    padding: 10px 15px;
    /* font-style: normal; */
    text-align: center;
}

.links {
    text-decoration: none;
    color: aliceblue;
}


@media (max-width: 1100px) {
    .gridContainer {
        grid-template-columns: repeat(3, 22vw);
            column-gap: 5px;
            row-gap: 15px;
    }

        .gridItemContainer p {
            font-size: 0.8rem;
        }
                .articleBody {
                    margin-bottom: 150px;
                    margin-top: 150px;
                }
}

@media (max-width: 750px) {
    .container {
    padding-top: 15px;
        padding-bottom: 15px;
        width: 80vw;
        height: auto;
    }

    .gridContainer {
        grid-template-columns: repeat(auto-fill, 26vw);
            justify-content: center;
            column-gap: 5px;
            row-gap: 25px;
            border-radius: 10px;
    }

    .gridItemContainer p {
        width: 100%;
        font-size: 0.7rem;
        text-overflow: clip;
        text-align: center;
        margin-right: 5px;
    }

    .gridItemContainer:hover p {
        width: 100%;
        font-size: 0.72rem;
        text-overflow: clip;
        text-align: center;
        margin-right: 5px;
    }

    .pageDesc {
        font-size: 0.85rem;
    }
}

@media (max-width: 700px) {
    .gridItemContainer .linksContainer {
            font-size: 0.68rem;
            top: 25%;
            left: 15%;
                right: 15%;
        }
    .gridItemContainer:hover .linksContainer {
        font-size: 0.68rem;
        top: 35%;
        left: 15%;
            right: 15%;
    }
        .gridItemContainer p {
            font-size: 0.65rem;
        padding: 7px 1px;

        }

        .gridItemContainer:hover p {
            font-size: 0.65rem;
        }

}

@media (max-width: 600px) {
    .gridItemContainer .linksContainer {
        font-size: 0.65rem;
        top: 23%;
    }

    .gridItemContainer:hover .linksContainer {
        font-size: 0.65rem;
        top: 30%;
    }

}

@media (max-width: 500px) {
    .gridContainer {
        grid-template-columns: repeat(2, 40vw);
        /* justify-content: center; */
        column-gap: 5px;
        row-gap: 25px;
        border-radius: 10px;
    }

    .gridItemContainer {
        overflow: hidden;
        width: 39vw;
        height: 39vw;
    }
}