.container {
    width: 100%;
    height: 35vh;
    /* display: grid; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.imageContainer {
    width: 120px;
    height: 120px;
    background-color: #181c28;
    border-radius: 100%;
    /* margin: 0; */
    margin-top: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.imageContainer .image {
    width: 105px;
    height: 105px;
    border-radius: 100%;
    object-fit: cover;
}

#username {
    color: whitesmoke;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem;
    margin: 0;
    margin-top: 4px;
}

.profileIconList {
    height: auto;
    width: 100%;
    padding: 0;
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
}

.row {
    list-style-type: none;
    color: whitesmoke;
    width: 30px;
    height: 30px;
    background-color: #181c28;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 70%;
}

.dataIcon {
    color: whitesmoke;
}

.row:hover {
    cursor: pointer;
    background-color: #894414;
}

@media (max-width: 1100px) {
    #username {
            font-size: 0.95rem;
        }
}

@media (max-width: 750px) {
    .imageContainer {
        width: 100px;
        height: 100px;
    }

    .imageContainer .image {
        width: 85px;
        height: 85px;
    }
    #username {
        font-size: 0.92rem;
    }
    .row {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 602px) {
    .imageContainer {
        width: 90px;
        height: 90px;
    }

    .imageContainer .image {
        width: 80px;
        height: 80px;
    }

    #username {
        font-size: 0.9rem;
    }

    .row {
        width: 23px;
        height: 23px;
    }
}

@media (max-width: 550px) {
    .imageContainer {
        width: 80px;
        height: 80px;
    }

    .imageContainer .image {
        width: 70px;
        height: 70px;
    }

    #username {
        font-size: 0.9rem;
    }

    .row {
        width: 22px;
        height: 22px;
    }
}
